import {
  isInteger,
  keys,
  each,
  includes,
} from 'lodash';

export const composeLineConfiguration = (templateConfiguration, defaultTemplateConfiguration) => {
  const lineConfiguration = {};

  each(keys(defaultTemplateConfiguration), (key) => {
    if (`${templateConfiguration?.[key]}`.length) {
      lineConfiguration[key] = templateConfiguration[key];
    } else {
      lineConfiguration[key] = defaultTemplateConfiguration[key];
    }
  });

  return lineConfiguration;
};

export const composeWithLineTemplateConfiguration = ({ minLine, maxLine, lineIncrement }) => ({
  minLine: (`${minLine}`.length ? +minLine : ''),
  maxLine: (`${maxLine}`.length ? +maxLine : ''),
  lineIncrement: (`${lineIncrement}`.length ? +lineIncrement : ''),
  type: 'WITH_LINE',
});

export const composeLineType = (minLine, lineIncrement) => {
  if (!isInteger(minLine) && isInteger(lineIncrement)) return 'Halves';
  if (isInteger(minLine) && isInteger(lineIncrement)) return 'Integers';
  return 'Both';
};

export const isLineConfigurationValueInvalid = (value) => {
  const valueAsString = `${value}`;
  if (!valueAsString?.length) return false;
  if (!includes(valueAsString, '.')) return value === 0;
  return includes(valueAsString, '.') && !includes(valueAsString, '.5');
};

export const isLineConfigurationLineOutOfBounds = (minLine, maxLine) => minLine > maxLine;

export const isLineConfigurationInvalid = (lineConfiguration) => lineConfiguration.type === 'WITH_LINE' && (
  isLineConfigurationValueInvalid(lineConfiguration.minLine)
  || isLineConfigurationValueInvalid(lineConfiguration.maxLine)
  || isLineConfigurationValueInvalid(lineConfiguration.lineIncrement)
  || isLineConfigurationLineOutOfBounds(lineConfiguration.minLine, lineConfiguration.maxLine)
);
