import { find, reduce, map } from 'lodash';

const footballConfigurationParameters = [
  {
    id: 'suspendOnAnyRisk',
    label: 'Any risk',
  },
  {
    id: 'suspendOnPlayRunning',
    label: 'Event is in between snaps',
  },
  {
    id: 'suspendOnRiskOfChallenge',
    label: 'Risk of challenge',
  },
  {
    id: 'suspendOnRiskOfTouchdown',
    label: 'Risk of touchdown',
  },
  {
    id: 'suspendOnRiskOfReviewedPlay',
    label: 'Risk of reviewed play',
  },
  {
    id: 'onlyWhenStateIsConfirmed',
    label: 'Uncertain event state',
  },
  {
    id: 'suspendOnRiskOfFieldGoal',
    label: 'Field Goal',
  },
  {
    id: 'suspendOnRiskOfPenalty',
    label: 'Penalty',
  },
  {
    id: 'suspendOnRiskOfTurnover',
    label: 'Turnover',
  },
];

const baseballConfigurationParameters = [
  {
    id: 'suspendOnBallInAir',
    label: 'Ball in the air',
  },
  {
    id: 'suspendOnBallPitched',
    label: 'Ball pitched',
  },
  {
    id: 'suspendOnBasesLoaded',
    label: 'Bases loaded',
  },
  {
    id: 'suspendOnBetStop',
    label: 'Bet stop',
  },
  {
    id: 'suspendOnIntentionalBallOnPlateAppearance',
    label: 'Intentional ball on plate appearance',
  },
  {
    id: 'suspendOnPitcherSubstitution',
    label: 'Pitcher substitution',
  },
  {
    id: 'suspendOnPlayStarted',
    label: 'Play started',
  },
  {
    id: 'suspendOnRunnersInMotion',
    label: 'Runners in motion',
  },
  {
    id: 'suspendOnStrikeOut',
    label: 'Strikeout',
  },
  {
    id: 'suspendOnThreeBalls',
    label: 'Three balls',
  },
  {
    id: 'suspendOnTwoStrikes',
    label: 'Two strikes',
  },
  {
    id: 'suspendOnVideoReview',
    label: 'Video review',
  },
];

const basketballConfigurationParameters = [
  {
    id: 'suspendOnPlayUnderReview',
    label: 'Play under review',
  },
];

const hockeyConfigurationParameters = [
  {
    id: 'whenEmptyNet',
    label: 'Empty net',
  },
  {
    id: 'onPossibleGoal',
    label: 'Possible goal',
  },
  {
    id: 'onPossiblePenalty',
    label: 'Possible penalty',
  },
  {
    id: 'onPossibleEmptyNet',
    label: 'Possible empty net',
  },
  {
    id: 'onPossiblePenaltyShot',
    label: 'Possible penalty shot',
  },
  {
    id: 'whenPenaltyShotPending',
    label: 'Penalty shot pending',
  },
  {
    id: 'whenVideoReviewOngoing',
    label: 'Video review ongoing',
  },
];

const allConfigurationParameters = [
  ...footballConfigurationParameters,
  ...baseballConfigurationParameters,
  ...basketballConfigurationParameters,
  ...hockeyConfigurationParameters,
];

export const parseRiskConfigurationFormData = (riskConfiguration) => ({
  id: riskConfiguration.gameRiskTemplate.gameRiskTemplateId,
  name: riskConfiguration.gameRiskTemplate.name,
  parameters: reduce(
    riskConfiguration.gameRiskConfiguration,
    (result, value, key) => {
      const foundParameter = find(allConfigurationParameters, { id: key });
      if (!foundParameter) return result;
      return [
        ...result,
        {
          ...foundParameter,
          value: !!value,
        },
      ];
    },
    [],
  ),
});

export const parseAllRiskConfigurationFormData = (riskConfigurations) => map(riskConfigurations, parseRiskConfigurationFormData);
